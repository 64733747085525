<template>
  <div class="bac">
    <div class="container flex">
      <div class="main-left">
        <div>
          <img class="img" :src="imgSrc" alt />
        </div>
        <div
          @click="pay"
          v-text="isPay ? '已关注' : '+关注'"
          :style="isPay ? 'background: #08ba06;' : ''"
          class="but"
        ></div>
      </div>
      <div class="main-right">
        <div>
          <span class="name">{{ name }}</span>
        </div>
        <div class="tags flex font">
          <span>
            关注
            <span>{{ paynum }} </span>
            人
          </span>
          |
          <span>
            粉丝
            <span>{{ fans }}</span>
            人
          </span>
        </div>
        <div class="cut-off"></div>
        <div class="font line">{{ introduction }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { loadDataID } from "../../../../models/teacher";
import commonApi from "@/api/common";
import { getAssetsFile } from "@/scripts/utils";
export default {
  data() {
    return {
      imgSrc: "",
      name: "",
      paynum: "",
      fans: "",
      introduction: "",
      isPay: false,
    };
  },
  mounted() {
    if (this.$route.params.isFake) {
      this.loadAssets();
    }
  },
  methods: {
    // 添加加数据
    async loadAssets() {
      const res = await commonApi.GetDocData("teacher_detail");
      console.log("res", res);
      if (res && res.code === 200) {
        const detailInfo = { ...res.data.detail };
        const suffix = detailInfo.imgSrc.split(".")[1];
        this.imgSrc = getAssetsFile(detailInfo.imgSrc, suffix);
        this.introduction = detailInfo.introduction;
        this.isPay = detailInfo.isPay;
        this.name = detailInfo.name;
        this.paynum = detailInfo.paynum;
        this.fans = detailInfo.fans;
        console.log("这是imgsrc", this.imgSrc);
      }
    },
    // loadDataID() {
    //   const res = loadDataID(+this.$route.params.id);
    //   if (res) {
    //     this.imgSrc = res.imgSrc;
    //     this.name = res.Name.substring(0, res.Name.length - 2);
    //     this.paynum = res.courseNum;
    //     this.fans = res.fans;
    //     this.introduction = res.introduce;
    //   }
    // },
    pay() {
      this.isPay = !this.isPay;
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.font {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.line {
  line-height: 20px;
}
.bac {
  background: #3b4460;
  .container {
    height: 240px;
    .main-left {
      margin-right: 30px;
      .but {
        cursor: pointer;
        width: 72px;
        height: 30px;
        background: #ff9800;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
        margin-top: 14px;
      }
    }
    .main-right {
      flex: 1;
      .name {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-right: 20px;
      }
      .tags {
        width: 20%;
        justify-content: space-between;
        margin-top: 20px;
      }
      .cut-off {
        width: 20px;
        height: 4px;
        background: #566188;
        margin-top: 18px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
