<template>
  <div>
    <TopInfo></TopInfo>
    <div class="container">
      <div class="statistics">
        <span class="filtrate font">课程列表</span>
        <span class="font color1">
          <span>共</span>
          <span class="color2" v-text="total"></span>
          <span>节</span>
        </span>
      </div>
      <InfoList :courseList="courseList" class="flex InfoList"></InfoList>
    </div>
  </div>
</template>

<script>
import TopInfo from "./components/TopInfo";
import InfoList from "./components/InfoList";
// import { loadData } from "../../../models/course";

import commonApi from "@/api/common";
import { getAssetsFile } from "@/scripts/utils";

export default {
  components: {
    TopInfo,
    InfoList,
  },
  data() {
    return {
      courseList: [],
      total: 0,
    };
  },
  created() {
    this.loadAssets();
  },
  methods: {
    async loadAssets() {
      let assetsList = [];
      const res = await commonApi.GetDocData("teacher_detail");
      if (res && res.code === 200) {
        assetsList = res.data.detail.courseList.map((item) => {
          const suffix = item.imgSrc.split(".")[1];
          return {
            ...item,
            isFake: true,
            imgSrc: getAssetsFile(item.imgSrc, suffix),
          };
        });
        this.courseList = this.courseList.concat(assetsList);
        this.total = this.courseList.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.font {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.color1 {
  color: #666666;
}
.color2 {
  color: #3984fc;
}
.statistics {
  margin: 19px 0 19px;
  .filtrate {
    font-size: 20px;
    margin-right: 10px;
  }
}
//列表
.InfoList {
  display: grid;
  grid-template-columns: 285px 285px 285px 285px;
  grid-gap: 30px 20px;
  margin-bottom: 60px;
}
</style>
