<template>
  <div>
    <div
      class="info-list"
      v-for="(item, index) in courseList"
      :key="index"
      @click="$router.push('/rec/detail/' + item.id)"
    >
      <img :src="item.imgSrc" class="img" alt />
      <div class="title">{{ item.courseName }}</div>
      <div class="Num">{{ item.views }}人观看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseList: {
      type: Array,
      default: () => [],
    },
    Show: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.info-list {
  cursor: pointer;
}
.title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin: 10px 0;
}
.Num {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}
.img {
  width: 285px;
  height: 160px;
}
</style>
